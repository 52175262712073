import styled from 'styled-components/macro';

import { ParallaxGroup, ParallaxLayer } from '../Utils';

export const PageTopContainer = styled(ParallaxGroup)`
  height: 100%;
`
export const BackDrop = styled(ParallaxLayer)`
  background-image: url('./lagos_palms.jpg');
  background-blend-mode: multiply;
  background-size: cover;
  background-color: rgba(130,50,75,.4);
  box-shadow: 0 2px 25px black;
  will-change: background-color;
  background-position-x: 46%;
  animation: darker 2s ease-in 1s forwards;
  z-index: 1;
  @media only screen and (min-width: 813px) {
    background-position-x: 30%
  }
  @keyframes darker {
    100%{
      background-color: rgba(90,50,75, 0.8);
    }
  }
`
export const DownArrowContainer = styled.div`
  cursor: pointer;
  z-index: 5;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  animation: bounce 2s infinite 5s;
  animation-play-state: ${props => (props.wasClicked ? 'paused' : 'running')};
  @keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-8px);
  }
}
`