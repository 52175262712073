import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Title from '../Title';
import { ParallaxLayer, ZWrapper } from '../Utils';
import { PageTopContainer, BackDrop, DownArrowContainer } from './styled';

const PageTop = ({ nextRef }) => {
  return (
    <PageTopContainer>
      <BackDrop type="base" />
      <ZWrapper z="1">
        <ParallaxLayer type="fore">
          <Title />
        </ParallaxLayer>
      </ZWrapper>
      <DownArrow scrollTo={nextRef} />
    </PageTopContainer>
  );
};
PageTop.propTypes = {
  nextRef: PropTypes.objectOf(PropTypes.any),
};
PageTop.defaultProps = {
  nextRef: {},
};
function DownArrow({ scrollTo }) {
  const [wasClicked, clicking] = useState(false);
  const clickHandler = (e) => {
    e.preventDefault();
    clicking(true);
    scrollTo.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <DownArrowContainer onClick={clickHandler} wasClicked={wasClicked}>
      <i className="fa fa-chevron-down" alt="&#x25BC" />
    </DownArrowContainer>
  );
}
DownArrow.propTypes = {
  scrollTo: PropTypes.objectOf(PropTypes.any),
};
DownArrow.defaultProps = {
  scrollTo: {},
};
export default PageTop;
