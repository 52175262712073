import React from 'react';
import PropTypes from 'prop-types';

import {
  ProjItemContainer,
  ProjTitle,
  ProjLinks,
  ProjBlurb,
  ExtLinkButton,
  GitHubButton,
  GitHubSpan,
} from './styled';

const ProjectItem = ({ projName, sshot, blurb, repo, appURL }) => {
  return (
    <ProjItemContainer sshot={sshot} appURL={appURL}>
      <ProjTitle>{projName}</ProjTitle>
      <ProjButtons repo={repo} appURL={appURL} />
      <ProjBlurb><p>{blurb}</p></ProjBlurb>
    </ProjItemContainer>
  );
};

ProjectItem.propTypes = {
  projName: PropTypes.string,
  sshot: PropTypes.string,
  blurb: PropTypes.string,
  repo: PropTypes.string,
  appURL: PropTypes.string,
};
ProjectItem.defaultProps = {
  projName: 'Project',
  sshot: 'Image URL',
  blurb: 'Description',
  repo: 'github.com',
  appURL: null,
};

export const ProjButtons = ({ repo, appURL }) => {
  return (
    <ProjLinks appURL={appURL}>
      {appURL && (
        <ExtLinkButton href={appURL} target="_blank" rel="noopener noreferrer">
          <span>Visit Site</span>
        </ExtLinkButton>
      )}
      <GitHubButton href={repo} target="_blank" rel="noopener noreferrer">
        <GitHubSpan />
      </GitHubButton>
    </ProjLinks>
  );
};
ProjButtons.propTypes = {
  repo: PropTypes.string,
  appURL: PropTypes.string,
};
ProjButtons.defaultProps = {
  repo: 'github.com',
  appURL: null,
};

export default ProjectItem;
