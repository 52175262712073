import styled from 'styled-components/macro';
import { device } from '../../constants';

export const ProjHeader = styled.h2`
  text-align: center;
  margin-bottom: 10px;
  text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  :after {
    content: 'Projects';
  }
`
export const ProjContainer = styled.div`
  scroll-snap-align: start;
  @media ${device.tablet}, (orientation: landscape)  {
    margin-top: 25px;
  }
  @media ${device.tablet} {
    margin-top: unset
  }
`
export const ProjFlex = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  @media only screen and (orientation: landscape), ${device.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  };
  @media ${device.laptop} {
    width: 90%;
    justify-content: space-around;
  }
`