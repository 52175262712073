import styled from 'styled-components/macro';
import { device } from '../../../constants';

const projectVars = {
  mobileHeight: '40vh'
}
export const ProjTitle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .5s;
`
export const ProjLinks = styled.div`
  position: absolute;
  top: 50%;
  left: ${props => props.appURL ? "50%" : "-30%"};
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.appURL ? "space-between" : "center"};
  height: 50px;
  font-size: calc(10px + 1vw);
  width: 200%;
  transition: all .5s ease-out;
  @media ${device.tablet} {
    width: 250%;
  }
  @media ${device.laptop} {
    width: 300%;
    justify-content: space-around;
  }
`
export const ProjBlurb = styled.div`
  position: absolute;
  bottom: -100%;
  margin: 0 5px 5px;
  font-size: 12px;
  transition: all .5s;
  height: 32%;
  padding: 2px 9px;
  text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
`

export const ProjItemContainer = styled.div`
  position: relative;
  cursor: pointer;
  background-image:${props => (props.sshot ? ("url(" + props.sshot + ")") : ' ')};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: multiply;
  background-color: hsla(0, 0%, 41%, 1);
  width:100%;
  height: ${projectVars.mobileHeight};
  overflow: hidden;
  border-radius: 16px;
  border: solid #685A72 1px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  &:hover{
    ${ProjTitle}{
      height: 32%;
    }
    ${ProjLinks}{
      transition: all .5s .5s;
      width: 90%;
      left: 50%;
    }
    ${ProjBlurb}{
      bottom: 0;
      transition: all .5s .75s;
    }
  }
  @media only screen and (max-width: 823px) and (orientation: landscape){
    height: 80vh;
    width: 45%;
  }
  @media only screen and (orientation: landscape){
    margin: 10px;
    
  }
  @media ${device.tablet} {
    width: 400px;
    margin: 0 50px;
    height: 250px;
    width: 300px;
    margin: 10px;
  }
  @media ${device.laptop} {
    width: 500px;
    height: 300px;
  }
`
const ButtonStyle = styled.a`
	cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #fff;
  color: darkgrey;
  overflow: hidden;
  font-size: 16px;
  padding: 3%;
  text-decoration: none;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 12px;
  @media only screen and (min-width: 823px) and (orientation: landscape){
    padding: 14px;
  }
  span {
    width: 100%;
    -webkit-transition: all 0.3s;
    -webkit-backface-visibility: hidden;
    -moz-transition: all 0.3s;
    -moz-backface-visibility: hidden;
    transition: all 0.3s;
    backface-visibility: hidden;
  }
  :before{
    left: 0;
	  top: -100%;
    position: absolute;
    height: 100%;
    width: 100%;
    line-height: 2.5;
    font-size: 180%;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
  }
  @media ${device.laptop} {
    :hover{
      span {
        -webkit-transform: translateY(300%);
        -moz-transform: translateY(300%);
        -ms-transform: translateY(300%);
        transform: translateY(300%);
      }
      :before {
        top: -20%;
      }
    }
  }
`
export const ExtLinkButton = styled(ButtonStyle)`
  :before{
    font-weight: 900;
    font-family: "Font Awesome 5 Pro";
    font-size: 1.7em;
    @media only screen and (min-width: 823px) and (orientation: landscape){
      content: "\f35d";
    }
  }
`
export const GitHubButton = styled(ButtonStyle)`
  :before{
    font-family: "Font Awesome 5 Brands";
    @media only screen and (min-width: 823px) and (orientation: landscape){
      content: "\f113";
    }
  }
`
export const GitHubSpan = styled.span`
  text-align: center;
  &:before{
    content: "View Repo";
  }
  @media ${device.tablet} {
    &:before{
      content: "View Repository"; 
    }
  }
`
