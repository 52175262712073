import React from 'react';

import { TitleContainer, TitleText, SubText, Brace } from './styled';

export default function Title() {
  return (
    <TitleContainer>
      <TitleText>John Hartnett V</TitleText>
      <SubText>
        <Brace>&#123;</Brace>
        Web Developer
        <Brace>&#125;</Brace>
      </SubText>
    </TitleContainer>
  );
}
