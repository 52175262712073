const projects = [
  {
    projName: 'SharedScreen',
    repo: 'https://github.com/jjh5166/shared_screen',
    appURL: 'https://shared-screen.netlify.app/',
    sshot: 'https://s3.us-east-2.amazonaws.com/jjhv.me/img/shared_screen.png',
    blurb:
      'A Next.js/React client written in Typescript with a Node server using the TypeGraphQL framework. Search actors, directors and screenwriters to see where their paths have crossed. More features coming!',
  },
  {
    projName: 'PremPicks',
    repo: 'https://github.com/jjh5166/prempicks-web',
    appURL: 'https://prempicks-web.vercel.app/',
    sshot: 'https://s3.us-east-2.amazonaws.com/jjhv.me/img/prempicks.png',
    blurb:
      'A custom picks league for the English Premier League. Built with Ruby on Rails, Redis, Postgres and Python. Automated email reminders, API monitoring for scoring scripts. The app has been hosting the league since 2018 and continues to gain users every season! Currently undergoing a switch to Rails API-only and React client. ',
  },
  {
    projName: 'React Calendar',
    repo: 'https://github.com/jjh5166/react_calendar',
    appURL: 'https://jjhvcal.netlify.app/',
    sshot: 'https://s3.us-east-2.amazonaws.com/jjhv.me/img/react_cal.png',
    blurb:
      'A dynamic calendar application utilizing Redux Saga to manage API requests and themes. Displays weather data and random facts.',
  }
];
export default projects;
