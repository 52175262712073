import styled from 'styled-components/macro';
import { device } from '../../constants';

export const IconContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  > a:visited {
    color: white;
  }
  @media ${device.tablet} {
    flex-direction: row;
    width: 43%;
    margin-top: 18px;
  }
`;

export const IconLink = styled.a`
  color: white;
  margin: 0 4px;
  font-size: ${({ fontSize }) => `${fontSize}px`};
`;
export const EmailIconLink = styled(IconLink)`
  @media ${device.tablet} {
    display: none;
  }
`;