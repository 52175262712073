import React from 'react';

import Links from '../Links';
import { Sectional } from '../Utils';
import {
  AboutContainer,
  PicContainer,
  Pic,
  BlurbContainer,
  AboutBlurb,
  FullHeightContainer,
} from './styled';

const About = React.forwardRef((props, ref) => (
  <Sectional ref={ref}>
    <AboutContainer>
      <FullHeightContainer>
        <PicContainer>
          <Pic>
            <img
              src="https://s3.us-east-2.amazonaws.com/jjhv.me/img/profile_pic.jpg"
              alt="profile"
            />
          </Pic>
        </PicContainer>
        <Links />
      </FullHeightContainer>
      <BlurbContainer>
        <AboutBlurb>
          <p>
            <span>Hi, I'm John.</span><br />
            {` I’m a web developer with a background in financial operations. As a
            life long learner, I’m a quick study and always seeking new challenges.
            I like to think outside the box and come up with simple, creative solutions
            to complex problems. In my free time I’m an avid reader, a dilettante
            guitarist and a cinephile.`}
          </p>
        </AboutBlurb>
      </BlurbContainer>
    </AboutContainer>
  </Sectional>
));

About.displayName = 'About';

export default About;
