import React from 'react';

import ProjectItem from './ProjectItem';
import projects from './projects';
import { ProjHeader, ProjContainer, ProjFlex } from './styled';

export default function Projects() {
  return (
    <ProjContainer>
      <ProjHeader />
      <ProjFlex>
        <ProjectsMapped />
      </ProjFlex>
    </ProjContainer>
  );
}

function ProjectsMapped() {
  return projects.map((project) => {
    return (
      <ProjectItem
        key={project.projName}
        sshot={project.sshot}
        projName={project.projName}
        blurb={project.blurb}
        repo={project.repo}
        appURL={project.appURL}
      />
    );
  });
}
