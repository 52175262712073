import styled from 'styled-components/macro';
import { device } from '../../constants';

export const EmailLink = styled.div`
  width: 100%;
  display: none;
  justify-content: center;
  font-size: 27px;
  width: 100%;
  text-shadow: 2px 2px 3px rgba(0,0,0,0.3);
  > a, a:visited{
    color: white;
  }
  a::after {
    content: 'jjh5166@gmail.com';
  }
  i {
    margin-right: 9px
  }
  @media ${device.tablet} {
    display: flex;
  }
`;
export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;