import React from 'react';

import { IconContainer, IconLink, EmailIconLink } from './styled';

export default function Icons() {
  return (
    <IconContainer>
      <IconLink
        href="https://github.com/jjh5166"
        target="_blank"
        rel="noopener noreferrer"
        fontSize={42}
      >
        <i className="fab fa-github" />
      </IconLink>
      <IconLink
        href="https://www.linkedin.com/in/jjhv5/"
        target="_blank"
        rel="noopener noreferrer"
        fontSize={45}
      >
        <i className="fab fa-linkedin" />
      </IconLink>
      <IconLink
        href="https://www.goodreads.com/user/show/58250451-john"
        target="_blank"
        rel="noopener noreferrer"
        fontSize={45}
      >
        <i className="fab fa-goodreads" />
      </IconLink>
      <EmailIconLink
        href="mailto:jjh5166@gmail.com"
        fontSize={41}
      >
        <i className="fas fa-envelope" />
      </EmailIconLink>
    </IconContainer>
  );
}
