import styled from 'styled-components/macro';

import { device } from '../../constants';

export const AboutContainer = styled.div`
  position: relative;
  display: grid;
  height: 100%;
  grid-template-rows: 205px 1fr;
  grid-template-columns: unset;
  @media only screen and (min-height: 560px) and (orientation: portrait) {
    grid-template-rows: 215px 1fr;
  }
  @media only screen and (min-width: 580px) and (orientation: landscape) {
    grid-template-columns: 40% 60%;
    grid-template-rows: unset;
  }
  @media ${device.tablet} {
    height: unset;
    padding: 35px 0;
  }
`
export const Pic = styled.div`
  clip-path: circle(94px at center);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
export const PicContainer = styled.div`
  clip-path: circle(96.5px at center);
  background: linear-gradient(to bottom right,#685A72, white, #685A72);
`
export const FullHeightContainer = styled.div`
  min-height: 285px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media ${device.tablet} {
    flex-direction: column;
  }
`
export const BlurbContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  margin-top: 10px;
  @media only screen and (min-width: 580px) and (orientation: landscape){
    justify-content: space-around;
  }
`
export const AboutBlurb = styled.div`
  position: relative;
  font-size: calc(10px + 1vh);
  color: white;
  text-align: left;
  line-height: 1.5;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 0 2%;
  padding: 28px;
  max-height: 285px;
  @media ${device.mobileM} { 
    max-height: 97%;
  }
  @media only screen and (max-width: 823px) and (orientation: landscape){
    padding: 18px;
    margin: 5px 10px;
  }
  @media ${device.tablet} { 
    margin-top: 30px;
    border-radius: 35px;
  }
  @media ${device.laptop} { 
    margin: 3% 3% 0;
    max-height: 85%;
  }
  p{
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    span{
      font-weight: 500;
      font-size: 1.5em;
    }
  }
`
