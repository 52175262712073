import React from 'react';

import { EmailLink, LinksContainer } from './styled';
import Icons from '../Icons';

export default function Links() {
  return (
    <LinksContainer>
      <EmailLink>
        <a href="mailto:jjh5166@gmail.com">
          <i className="fas fa-envelope" />
        </a>
      </EmailLink>
      <Icons />
    </LinksContainer>
  );
}