import React, { useRef } from 'react';
import Div100vh from 'react-div-100vh';

import PageTop from '../PageTop';
import About from '../About';
import Projects from '../Projects';

import { AppContainer } from './styled';

const App = () => {
  const downTo = useRef(null);
  return (
    <Div100vh>
      <AppContainer>
        <PageTop nextRef={downTo} />
        <About ref={downTo} />
        <Projects />
      </AppContainer>
    </Div100vh>
  );
};

export default App;
