import styled from 'styled-components/macro';

import { ParallaxContainer, ParallaxGroup } from '../Utils';

export const AppContainer = styled(ParallaxContainer)`
  background: linear-gradient( rgba(95,53,90,0.7), rgba(95,53,90,0.91)
    ),repeat url('./qbkls.png') 300px;
  border-style: none;
  @media only screen and(min-width: 823px) and(orientation: landscape){
    background-size: auto;
  }
`
export const MainContainer = styled(ParallaxGroup)`
  height: 300%;
`